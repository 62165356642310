"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React from "react";
import { useTranslation } from "react-i18next";
import i18nConfig, { Locale } from "../../../../../../i18nConfig";
import {BiMailSend, BiPhoneCall} from "react-icons/bi";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const router = useRouter();
  const currentPathname = usePathname();

  const handleChange = (e: Locale) => {
    const newLocale = e;

    // set cookie for next-i18n-router
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = date.toUTCString();
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;

    // redirect to the new locale path
    if (
      currentLocale === i18nConfig.defaultLocale &&
      !i18nConfig.prefixDefault
    ) {
      router.push("/" + newLocale + currentPathname);
    } else {
      router.push(
        currentPathname.replace(`/${currentLocale}`, `/${newLocale}`),
      );
    }

    router.refresh();
  };

  const lngMapper: { [key: Locale]: string } = {
    en: "English",
    fr: "French",
  };

  return (
    <footer className="w-full bg-[#282C2C]">
      <div className="mx-auto w-full max-w-6xl px-4">
        <div className="flex w-full flex-col items-start">
          <div className="mb-[18px] flex w-full flex-col items-center justify-between gap-[18px] md:mb-2 md:flex-row">
            <div className="flex items-center gap-1.5">
              <Image
                  alt="Logo ClearAssure.ch"
                  width="150"
                  height="150"
                  src="https://cdn.1cdn.app/application/CLEARASSURE/25021311192136_logo_clearassure_white.png"
              />
            </div>

            <div className="flex items-center">
              {/*
              <Link
                  className="group flex items-center gap-3"
                  href="tel:+410215615336"
              >
                <BiPhoneCall color="white" size={30}/>
                <span className="text-sm leading-8 text-white group-hover:underline md:text-base">
            (+41) 021 561 53 36
          </span>
              </Link>
              */}
              <Link
                  className="group flex items-center gap-3"
                  href="mailto:contact@clearassure.ch"
              >
                <BiMailSend color="white" size={30}/>
                <span className="text-sm leading-8 text-white group-hover:underline md:text-base">
            contact@clearassure.ch
          </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center text-white text-xs pb-4 px-5">
        <small>
          L&apos;intégralité de ce site est sous le contrôle de Pink Swan SA, qui agit en tant que régulateur et garant de la conformité de la plateforme. Mandaté pour assurer une transparence totale, Pink Swan SA veille à ce que chaque étape du processus respecte rigoureusement les normes et standards établis.
        </small>
      </p>

      <p className="text-center text-white text-xs pb-4">
        <small>
          Made with ❤️ in St Prex | Vaud | 🇨🇭 by our passionate and engaged
          team. <span>&copy; ClearAssure.ch - 2025</span>
        </small>
      </p>
    </footer>
  );
};

export default Footer;
